import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/nav',
    name: 'BottomNav',
    component: () => import('../components/BottomNav.vue'),
    redirect: '/nav/index',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('../views/Index.vue'),
      },
      {
        path: 'cart',
        name: 'Cart',
        component: () => import('../views/Cart.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token');
  if (to.path === '/login') {
    next();
  } else {
    if (token) {
      next();
    } else {
      next('login');
    }
  }
});

export default router;
