import axios from 'axios';

const http = axios.create({
  // baseURL: '',
});

// 设置请求拦截器
http.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return error;
  }
);

// 设置响应拦截器
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return error;
  }
);

export default http;
