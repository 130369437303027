import Mock from 'mockjs';

Mock.setup({
  timeout: '200-2000',
});

Mock.mock(/\/api\/test/, 'get', (req: any) => {
  console.log(req);
  return {
    code: 0,
    msg: '测试成功',
    data: '',
  };
});

// 登录
Mock.mock(/\/api\/login/, 'post', (req: any) => {
  // console.log(req);
  // 用户池
  const users = [
    {
      username: 'admin',
      password: '123',
    },
    {
      username: 'steven',
      password: '666',
    },
  ];
  const { username, password } = JSON.parse(req.body);
  // console.log(username, password);
  const user = users.filter(
    (value) => value.username === username && value.password === password
  );
  // console.log(user);
  if (user.length) {
    return {
      code: 0,
      msg: '登录成功',
      data: {
        token: 'test',
      },
    };
  } else {
    return {
      code: -1,
      msg: '账号或密码错误',
      data: '',
    };
  }
});

// 首页
Mock.mock(/\/api\/home/, 'get', () => {
  return {
    code: 0,
    msg: '请求成功',
    data: {
      banner: [
        'https://file.xdclass.net/video/2021/1-lbt/VIP/vip1299.png',
        'https://file.xdclass.net/video/2021/62-paas/lbt-paas.png',
        'https://file.xdclass.net/video/2021/59-Postman/59lbt.png',
      ],
      hotGoods: [
        {
          img: 'https://file.xdclass.net/video/2021/61-RabbitMq/zt.jpg',
          title: '容器化分布式集群实战',
          id: 1,
        },
        {
          img:
            'https://file.xdclass.net/video/2020-12%20%E5%AE%98%E7%BD%91%E8%B6%85100k%E4%B8%BB%E5%9B%BE%E6%9B%B4%E6%96%B0/%E4%B8%BB%E5%9B%BE/57-alibabacloud.jpg',
          title: 'AlibabaCloud',
          id: 2,
        },
        {
          img: 'https://file.xdclass.net/video/2020/SSM/zt-ssm.png',
          title: 'ssm新版',
          id: 3,
        },
        {
          img:
            'https://file.xdclass.net/video/2020-12%20%E5%AE%98%E7%BD%91%E8%B6%85100k%E4%B8%BB%E5%9B%BE%E6%9B%B4%E6%96%B0/%E4%B8%BB%E5%9B%BE/37-yhq.jpeg',
          title: '微服务Dubbo',
          id: 4,
        },
        {
          img: 'https://file.xdclass.net/video/2021/59-Postman/Postman.png',
          title: 'Postman',
          id: 5,
        },
        {
          img: 'https://file.xdclass.net/video/2021/60-MLS/cover.jpeg',
          title: 'MybatisPlus',
          id: 6,
        },
        {
          img: 'https://file.xdclass.net/video/2020/SSM/zt-ssm.png',
          title: 'ssm新版',
          id: 7,
        },
        {
          img: 'https://file.xdclass.net/video/2021/61-RabbitMq/zt.jpg',
          title: 'RabbitMQ',
          id: 8,
        },
      ],
    },
  };
});

export default Mock;
