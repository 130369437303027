import { createStore } from 'vuex';

const store = createStore({
  state: {
    cartArr: JSON.parse(sessionStorage.getItem('cartArr') || '[]'), // 购物车
  },
  mutations: {
    // 加入购物车
    toCart(state: any, payload) {
      // console.log(payload);
      const cart = state.cartArr.find((value: any) => value.id === payload.id);
      // console.log(cart);
      if (cart) {
        cart.count += 1;
      } else {
        state.cartArr.push({
          ...payload,
          count: 1,
        });
      }
    },

    // 添加购物车
    addCart(state: any, payload) {
      const index = state.cartArr.findIndex(
        (value: any) => value.id === payload
      );
      // console.log(index);
      state.cartArr[index].count += 1;
    },

    // 减少购物车
    subCart(state: any, payload) {
      const index = state.cartArr.findIndex(
        (value: any) => value.id === payload
      );
      if (state.cartArr[index].count > 1) {
        state.cartArr[index].count -= 1;
      } else {
        if (confirm('确定删除该商品吗?')) {
          state.cartArr.splice(index, 1);
        }
      }
    },

    // 清空购物车
    clearCart(state: any) {
      state.cartArr = [];
    },
  },
  actions: {},
  modules: {},
  getters: {
    cartCount: (state) => {
      let count = 0;
      state.cartArr.forEach((value: any) => {
        count += value.count;
      });
      return count;
    },
  },
});

// 数据持久化
store.subscribe((mutations, state) => {
  sessionStorage.setItem('cartArr', JSON.stringify(state.cartArr));
});

export default store;
